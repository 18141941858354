import { TopicPreview } from "../../models";

type QueryValues = { [key: string]: string };
type ParamValues = { [key: string]: any } | undefined;
type OptionalArgument<T> = T extends undefined ? [] : [T];
type RequiredArguments<T> = {
    [P in keyof T]-?: boolean;
}

export class Page<T extends ParamValues = undefined> {
    pageName: string;
    path: string;
    requiredParams?: RequiredArguments<T>;

    get routeUrl(): string {
        var retVal = this.path;
        if (this.requiredParams) {
            for (var k in this.requiredParams) {
                retVal += `/:${k}${!this.requiredParams[k] ? '' : ''}`;
            }
        }
        return retVal;
    };

    getUrlWithQuery(val: ParamValues, queryVals: QueryValues | undefined): string {
        var retVal = this.path;
        for (var k in this.requiredParams) {
            if (val && val[k]) {
                retVal += `/${encodeURI(val[k])}`;
            } else if (this.requiredParams[k]) {
                console.warn(`Values for route do not include required url parameter: ${k}`);
            }
        }

        var queryString = "";
        
        if (queryVals) {
            var isFirst = true;
            for (var q in queryVals) {
                if (!isFirst)
                    queryString += "&";
                isFirst = false;
                queryString += q + "=" + encodeURI(queryVals[q]);
            }
        }

        if (queryString.length > 0)
            retVal += `?${queryString}`;

        return retVal;
    }

    getUrl(...vals: OptionalArgument<T>): string {
        var retVal = this.path;
        var val = vals[0];
        return this.getUrlWithQuery(val, undefined);
    }

    constructor(pageName: string, path: string, paramTypes?: RequiredArguments<T>) {
        this.pageName = pageName;
        this.path = path;
        this.requiredParams = paramTypes;
    }
}

export const Pages = {
    Home: new Page("Home", "/"),
    BoatLanding: new Page<{ boatLandingId?: string }>("BoatLanding", "/sc-boat-landing", { boatLandingId: false }),
    BoatLandings: new Page<{ zoom?: number, latitude?: number, longitude?: number }>("BoatLandings", "/sc-boat-landings", { zoom: false, latitude: false, longitude: false }),
    BoatLandingsHome: new Page("BoatLandings", "/sc-boat-landings"),
    Charters: new Page("Charters", "/fishing-charters"),
    Events: new Page("Events", "/charleston-events"),
    FishingSpot: new Page<{ locationId: string }>("FishingSpot", "/sc-fishing-reef", { locationId: false }),
    FishingSpots: new Page<{ zoom?: number, latitude?: number, longitude?: number }>("FishingSpots", "/sc-fishing-spots", { zoom: false, latitude: false, longitude: false }),
    FishingSpotsHome: new Page("FishingSpots", "/sc-fishing-spots"),
    InshoreCharters: new Page("inshore/Charters", "/inshore/charters"),
    InshoreReports: new Page("inshore/Reports", "/inshore/reports"),
    OffshoreCharters: new Page("offshore/Charters", "/offshore/charters"),
    OffshoreReports: new Page("offshore/Reports", "/offshore/reports"),
    OffshoreWaveForecast: new Page("OffshoreWaveForecast", "/offshore-wave-forecast"),
    Privacy: new Page("Privacy", "/charleston-fishing-privacy"),
    SolunarCalendar: new Page("SolunarCalendar", "/solunar-fishing-calendar"),
    Sponsors: new Page("Sponsors", "/fishing-sponsors"),
    Tides: new Page<{ stationId?: string }>("Tides", "/tides", { stationId: false }),
    TidesHome: new Page("Tides", "/tides")
};


export enum Forums {
    OffshoreDiscussion = 28,
    OffshoreReports = 14,
    InshoreDiscussion = 23,
    InshoreReports = 58,
    FreshwaterDiscussion = 40
}

export const baseForumUrl: string = 'https://forum.charlestonfishing.com';

export const getTopicUrl = (topic?: TopicPreview) => {
    return `${baseForumUrl}/t/${topic?.id}`;
}

export const getForumUrl = (forumType?: Forums) => {
    var retVal = baseForumUrl;

    if (forumType) {
        retVal += `/c/${forumType}`;
    }

    return retVal;
}
