import loadable from '@loadable/component';
import React from 'react';
import { useRoutes } from 'react-router-dom';

import './assets/site.css';
import { Pages } from './components/shared/Pages';

const Error = loadable(() => import('./components/pages/Error'));

const config = {
    ErrorComponent: () => <Error />
};

interface AsyncComponentProps {
    name: string;
}
const AsyncComponent: React.FC<AsyncComponentProps> = ({ name, ...props }) => {
    const Component = loadable(() => import(`./components/pages/${name}`));
    if (Component)
        return <Component {...props} />;
    return <div>No component found!</div>
};

const getPageRoutes = () => Object.values(Pages).map((page) => ({
    path: page.routeUrl,
    element: <AsyncComponent key={page.pageName} name={page.pageName} />
}));

export default function App() {
    return useRoutes(
        getPageRoutes()
    );
}
/*const App = () => (
    <Routes>
        <Route path="*" element={<Solunar />} />
        
        { 
        Object.values(Pages).map((page) => (
        <Route key={page.pageName} path={page.routeUrl} element={AsyncComponent loadable(() => import(`./components/pages/${page.pageName}`)) } />
        )
        }
        
    </Routes>
);

export default App;*/
