import { loadableReady } from '@loadable/component';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
const root = document.getElementById('root');

loadableReady().then(() => {
    hydrateRoot(root!, 
        <BrowserRouter>
            <App />
        </BrowserRouter>
    )
});

if (module.hot) {
  module.hot.accept();
}
